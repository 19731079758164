const footerCategorieInfo = [
    "Courroie",
    "Freins",
    "Diagnostics",
    "Moteur",
    "Chauffage",
    "Filtre",
    "Huiles",
    "Direction",
    "Suspension",
    "Pneus",
    "Transmission"
]

export default footerCategorieInfo;