const servicesInfo = [
    "batterie",
    "climatisation",
    "distribution",
    "vidange",
    "revision",
    "freinage",
    "electronique",
    "suspension",
    "echappement"
]

export default servicesInfo;