import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import galerieData from '../data/galerie.js'
import img1 from '../images/Galerie/a4.jpeg'
import img2 from '../images/Galerie/amgE63.jpeg'
import img3 from '../images/Galerie/audi.jpeg'
import img4 from '../images/Galerie/moteur2.jpeg'
import img5 from '../images/Galerie/moteur_amg.jpeg'
import img6 from '../images/Galerie/moteur_mercedes.jpeg'
import img7 from '../images/Galerie/rs6.jpeg'
import img8 from '../images/Galerie/s3.jpeg'
import img9 from '../images/Galerie/test.jpg'
import img10 from '../images/Galerie/tuyau1.jpeg'
import img11 from '../images/Galerie/voiture_accidente.jpeg'
import img12 from '../images/Galerie/voiture_accidente2.jpeg'

export default function Galerie() {
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        sourceIndex: 0
      });

      function openLightboxOnSource(sourceIndex) {
        setLightboxController({
          toggler: !lightboxController.toggler,
          sourceIndex: sourceIndex
        });        
      }

    return (
        <>
        <h1 className="titre_galerie">Galerie</h1>
        <div className="galerie_img">
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(0)}>
                    <img src={img1}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(1)}>
                    <img src={img2}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(2)}>
                    <img src={img3}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(3)}>
                    <img src={img4}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(4)}>
                    <img src={img5}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(5)}>
                    <img src={img6}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(6)}>
                    <img src={img7}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(7)}>
                    <img src={img8}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(8)}>
                    <img src={img9}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(9)}>
                    <img src={img10}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(10)}>
                    <img src={img11}/>
            </div>
            <div className="galerie_img_inside" onClick={() => openLightboxOnSource(11)}>
                    <img src={img12}/>
            </div>
        </div>
        <FsLightbox
                toggler={lightboxController.toggler}
                sourceIndex={lightboxController.sourceIndex}
        thumbs={[
          null,
          <img src={img1}/>,
          <img src={img2}/>,
          <img src={img3}/>,
          <img src={img4}/>,
          <img src={img5}/>,
          <img src={img6}/>,
          <img src={img7}/>,
          <img src={img8}/>,
          <img src={img9}/>,
          <img src={img10}/>,
          <img src={img11}/>,
          <img src={img12}/>
        ]}
        sources={[
            <img src={img1}/>,
            <img src={img2}/>,
            <img src={img3}/>,
            <img src={img4}/>,
            <img src={img5}/>,
            <img src={img6}/>,
            <img src={img7}/>,
            <img src={img8}/>,
            <img src={img9}/>,
            <img src={img10}/>,
            <img src={img11}/>,
            <img src={img12}/>
        ]}
      />
        </>
    )
}
