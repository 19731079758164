const footerServicesInfo = [
    "Mécanique générale",
    "Vente véhicules d’occasion",
    "Entretien avec remise à zéro",
    "Diagnostique technique",
    "FAP catalyseur",
    "Electricité electronique",
    "Pneumatiques",
    "Dépannage et remorquage",
    "Contrôle technique",
    "Service carte grise"
]

export default footerServicesInfo;