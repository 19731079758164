import React from 'react'

export default function Footer_services({element}) {
    return (
        <div className="footer_services_description">
            <i className="fas fa-check"></i>
            <p>{element}</p>
        </div>
    )
}
