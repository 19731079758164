const infoCards = [{
        icon: "fas fa-car",
        textFrontH4: "Achat-Vente",
        p: "Consultez nos véhicules à vendre",
        voirPlus: "https://www.leboncoin.fr/profil/c96db91d-782d-4dd7-bf48-6c4de5a438e3/offres"

    },
    {
        icon: "fas fa-tools",
        textFrontH4: "Mecanique",
        p: "N'hésitez pas à nous contacter pour toute réparation mécanique",
        voirPlus: "/contact"

    },
    {
        icon: "fas fa-check-circle",
        textFrontH4: "Revision",
        p: "Ne tardez pas sur vos révisions pour la longévité de votre moteur, contactez-nous !",
        voirPlus: "/contact"

    },
    {
        icon: "fas fa-comments",
        textFrontH4: "Conseil",
        p: "Wolf Cars 95 est votre garage de proximité qui est à votre écoute pour vos besoins",
        voirPlus: "/services"

    }
]

export default infoCards